<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title v-if="dialogDeleteTitle" class="headline">{{
        dialogDeleteTitle
      }}</v-card-title>
      <v-card-title v-else class="headline"
        >{{ $t("FORMS.are_you") }}?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('closeDelete')">{{
          $t("FORMS.cancel")
        }}</v-btn>
        <v-btn color="primary" @click="$emit('deleteItemConfirm')">OK</v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteModalDialog",
  props: ["dialogDelete", "dialogDeleteTitle"],
};
</script>
