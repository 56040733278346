<template>
  <div class="text-center">
    <v-dialog v-model="fileModalData.dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline"
                >{{ $t("MEDIA_LIBRARY.rename_file") }}
              </span>
              <v-btn @click="handleCloseModalForm" icon class="close-button">
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="formModel.new_name"
                  :label="$t('FORMS.name')"
                  :rules="newNameRules"
                  :error-messages="messages['new_name']"
                  @keyup="messages['new_name'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleSaveFileModalForm">
            {{ $t("FORMS.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { FILE_ENDPOINT } from "./Browser";
export const initialFormData = () => ({
  old_name: "",
  new_name: "",
});

export default {
  name: "FileModalForm",
  props: ["fileModalData", "actualDirectory"],
  data: () => ({
    loader: false,
    formModel: Object.assign({}, initialFormData()),
    formValid: false,
    messages: {},
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length < 250 || "This field must be max 250 characters",
    ],
    newNameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length < 250 || "This field must be max 250 characters",
    ],
  }),
  watch: {
    fileModalData: {
      deep: true,
      handler(value) {
        if (value.old_name) {
          this.formModel.old_name = this.formModel.new_name = value.old_name;
        }
      },
    },
  },
  methods: {
    handleCloseFileModalForm() {
      this.$emit("closeFileModalForm");
    },
    handleSaveFileModalForm() {
      let model = Object.assign({}, this.formModel);
      if (model.old_name != model.new_name) {
        model.path = this.actualDirectory;
        this.$refs.form.validate();
        if (this.formValid) {
          this.loader = true;
          ApiService.put(FILE_ENDPOINT, model)
            .then(() => {
              this.$emit("saveFileModalForm");
              this.loader = false;
            })
            .catch((error) => {
              console.log(error);
              this.loader = false;
            });
        }
      }
    },
  },
};
</script>
